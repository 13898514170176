.subscription {
	&.block {
		.title {
			display: block;
			border: unset;
		}
		article {
			padding-top: .5rem
		}
	}
	.nav-tabs {
		border-bottom: 1px solid #4F49A1;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		font-weight: 400;
		margin-top: 2rem;
		padding-bottom: 1rem;

		.nav-link {
			display: block;
			padding: 0.5rem 2rem;
			color: #98A2B3;
			text-decoration: none;
			transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
			position: relative;
			cursor: pointer;
			&.active {
				color: white;
				&:after {
					display: block;
					content: '';
					width: 100%;
					height: 0.4rem;
					background: #4F49A1;
					position: absolute;
					bottom:  -1.2rem;
					left: 0;
				}
			}
		}
		li {
			display: list-item;
			text-align: -webkit-match-parent;
		}
	}
	.grid {
		grid-template-columns: 0.7fr 1fr;
	}
	.grid-summary {
		grid-template-columns: auto auto auto;
	}
	.grid-invoices {
		grid-template-columns: 1fr 1fr;

	}
	.grid, .grid-summary, .grid-invoices {
		display: grid;
		grid-gap: 2rem;
		> div {
			display: grid;
			grid-template-rows: auto 1fr;
		}
		.element {
			margin-top: 1rem;
			background-color: white;
			color: #667085;
			&.summary {
				padding: 2rem;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				min-height: 17rem;
			}
			&.invoices {
				width: fit-content;
				> div {
					display: flex;
					justify-content: space-around;
					align-items: center;
					min-height: 4.8rem;
					padding-left: .5rem;
					padding-right: .5rem;

					span {
						margin-right: 1rem;
						margin-left: 1rem;
					}
					&:nth-child(even) {
						background-color: #EEF4FF;
					}
					.download {
						cursor: pointer;
					}
				}
				table {
					thead {
						border-bottom: 1px solid #cfcfcf;
					}
					td, th {
						padding: 1rem;
						text-align: left;
						svg {
							width: 20px;
							height: 20px;
						}
						label {
							padding: 0;
						}
					}
					tbody {
						tr:nth-child(even) {
							background-color: #EEF4FF;
						}
					}
					.download {
						cursor: pointer;
					}
				}
			}

			&.active-plan {
				display: flex;
				height: 12rem;
				flex-direction: column;
				justify-content: space-around;
				h2, .price {
					font-weight: 600;
					font-size: 1.8rem;
				}
				.price {
					color: #6a62d6;
				}
				.badge {
					font-size: small;
					background-color: #6a62d691;
					border-radius: 5px;
					padding: 1rem;
					color: white;
				}
			}
			&.plans {
				display: flex;
				height: 15rem;
				justify-content: space-between;
				.content {
					margin: auto 1.5rem;
					h2 {
						font-weight: 600;
						font-size: 1.8rem;
					}
				}
				.price {
					display: grid;
					margin: auto 1.5rem;
					.month {
						color: #6a62d6;
						font-weight: bolder;
						font-size: 1.8rem;
					}
					.year {
						color: #c3c3c3;
					}
				}
			}
		}
	}
	.method {
		margin-top: 1rem;
		width: 100%;
		border: 1px solid #6a62d6;
		border-radius: 5px;
		display: flex;
		justify-content: space-between;

		.number {
			text-transform: capitalize;
		}
		.image {
			img {
				width: 7rem;
			}
		}
		.expire {
			color: #98A2B3;
		}
		.edit {
			cursor: pointer;
		}
		&.selected {
			border-color: red;
			background-color: #2c4d8f;
		}
	}
	.modal {
		background: #F9FAFB;
		border-radius: 5px;
		z-index: 999;
		h3, label, input {
			color: #000000;
			font-weight: normal;
		}
		h3 {
			font-size: 1.8rem;
		}
		input {
			height: 1.4rem;
			padding: 1.3rem
		}
		label {
			font-size: 1.5rem;
			text-transform: unset;
		}
		.d-grid {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 2rem;
		}
	}
	.StripeElement {
		display: block;
		margin: 10px 0 5px 0;
		max-width: 500px;
		padding: 10px 14px;
		border-radius: 4px;
		background: hsla(0,0%,100%,.12);
		border: 1px solid #c3c2c2;
	}
}

@media (max-width: $mobile) {
	.modal {
		width: 80%;
		.d-grid {
			grid-template-columns: 1fr !important;
		}
	}
}