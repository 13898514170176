.banner {
	overflow-x: hidden;
	margin-bottom: 20px;
	position: relative;
	&.direct {
		width: 90%;
		margin: auto;
		padding-top: 10rem;
	}

	.slides-container {
		.slide {
			color: #FFFFFF;
			position: relative;
			overflow: hidden;
			> img {
				width: 100vw;
				max-height: 60vh;
				object-fit: cover;
				user-select: none;
				position: relative;
				display: block;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.8;
				background: linear-gradient(
					180deg,
					rgba(7, 17, 32, 0.72) 0%,
					rgba(7, 17, 32, 0) 100%
				);
				z-index: 5;
			}

			&.display-2 {
				.title {
					position: absolute;
					bottom: 30%;
					z-index: 5;
					width: 90%;
					left: 0;
					right: 0;
					margin: auto;
					.main-title {
						width: 60%;
						h1 {
							font-size: 3vw;
							font-weight: 900;
							margin-bottom: 1rem;
							display: block;
							span {
								display: block;
								text-align: center;
							}
						}
					}

					.buttons {
						a:first-of-type {
							display: inline-flex;
							&:before {
								content: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12.7286 8.81416L1.42357 15.8599C0.982635 16.1347 0.406742 15.9929 0.137279 15.5432C0.0475027 15.3934 0 15.2212 0 15.0456V0.954235C0 0.427225 0.418914 0 0.935669 0C1.10784 0 1.27667 0.0484453 1.42357 0.140003L12.7286 7.1857C13.1695 7.46051 13.3085 8.04783 13.0391 8.49752C12.9617 8.62669 12.8552 8.73523 12.7286 8.81416Z' fill='white'/></svg>");
								margin-right: 5px;
							}
						}
						margin-top: 2rem;
					}
				}

				.dots {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
				.play {
					cursor: pointer;
					z-index: 5;
					position: absolute;
					top: calc(50% - 5rem) ;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					width: 6rem;
					height: 6rem;

					background: $white;

					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						path {
							fill: #EE1C25;
						}
						width: 3.5rem;
						height: 3rem;
						position: relative;
						left: 0.1rem;
					}
				}
			}
		}
	}

	.slick-dots {
		position: absolute;
		bottom: 5%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 5;
		display: flex;

		li {
			margin-right: 8px;

			button {
				cursor: pointer;
				display: block;
				border: none;
				padding: 0;
				background-color: rgba(196, 196, 196, 0.15);
				opacity: 0.6;
				position: relative;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				&::before {
					display: none;
				}
			}
			&.slick-active {
				button {
					background-color: $white !important;
					opacity: 1 !important;
				}
			}
		}
	}

	.arrow-slideshow {
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 1.5rem;
		padding: 2.2rem 1rem;
		border: none;
		position: absolute;
		top: calc(50% - 4.8rem);
		left: 1rem;
		transform: scale(100%);
		transition: all 0.3s linear;
		z-index: 90;
		&:hover {
			transform: scale(102%);
			transition: all 0.3s linear;

			svg {
				transition: all 0.3s linear;
				transform: scale(98%);
			}
		}
		svg {
			transition: all 0.3s linear;
			transform: scale(100%);
			fill: $white;
			width: 2rem;
		}
		&.right {
			left: unset;
			right: 1rem;
		}
		&.disabled {
			svg {
				opacity: 0.3;
			}
		}
	}
}

@media (max-width: $mobile) {
	.banner {
		.slides-container {
			margin-top: 7rem;
			.slide {
				&.display-2 {
					.title {
						bottom: 15%;
						.main-title {
							width: 100%;
							text-align: center;
							h1 {
								font-size: 5vw;
							}
						}
					}
					.buttons {
						text-align: center;
						width: 75%;
						margin: 2rem auto 0;
						a {
							margin-left: 1rem;
							margin-right: 1rem;
							display: block !important;
							&:first-child {
								margin-bottom: 1rem;
							}
						}
					}
				}

				.slick-dots {
					button {
						height: 0.3rem !important;
					}
				}
			}
		}

	}
}
