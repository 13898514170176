//COLORS

$background: #1F1A56;
$white: #ffffff;
$red: #EE1C25;
$navigation: #4F49A1;
$green: rgba(33, 173, 86, 1);
$blue: rgb(19, 40, 82);

$scroll: rgb(32, 32, 32);
//FONTS
@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    src: local('Gotham'), url(./fonts/Gotham-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 600;
    src: local('Gotham'), url(./fonts/Gotham-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 900;
    src: local('Gotham'), url(./fonts/Gotham-Bold.otf) format('opentype');
}
/*@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Black.otf) format('opentype');
}*/

$fontRegular: "Gotham", sans-serif;

//ANIMATION

$fade: 0.04s;

//MEDIA QUERY

$mobile: 1000px;
$desktop: 1440px;
