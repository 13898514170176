@import "./reset.css";
@import "./animation.css";

@import "./settings";
@import "./functions";

html {
	font-size: 62.5%;
	@media (max-width: $mobile) {
		overflow-x: hidden !important;
	}
}

body {
	//FONT
	font-size: 14px;
	background-color: $background;
	color: $white;
	font-family: $fontRegular;

	.App {
		overflow: hidden;
		//IMPORT HEADER
		@import "./components/header";

		//PAGES
		@import "./pages/explorer";
		@import "./pages/categories";
		@import "./pages/disciplines";
		@import "./pages/player";
		@import "./pages/account";
		@import "./pages/info";

		//COMPONENTS
		@import "./components/components";

		//FOOTER
		@import "./components/footer";

		//CAROUSEL
		@import "../../node_modules/slick-carousel/slick/slick.css";
		@import "../../node_modules/slick-carousel/slick/slick-theme.css";
	}
}

//CLASSES
a {
	text-decoration: none;
	color: $white;
}
.text-bold {
	font-weight: bolder;
}
.text-small {
	font-size: small;
}
.hidden {
	display: none;
}

.container {
	width: 90%;
	margin: auto;
	position: relative;
}
.border {
	border: 1px solid #8d8d8d !important;
}
.position-absolute {
	position: absolute;
	z-index: 9;
}
.position-fixed {
	position: fixed;
}
.position-relative {
	position: relative;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.float-right {
	float: right;
}
.m-auto {
	margin: auto !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mx-1 {
	margin-left: .5rem;
	margin-right: .5rem;
}
.mx-2 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mx-3 {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}
.mx-4 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.m-0 {
	margin: 0 !important;
}
.mr-1 {
	margin-right: 0.5rem;
}
.mr-2 {
	margin-right: 1rem;
}
.mr-3{
	margin-right: 1.5rem;
}
.mr-4{
	margin-right: 2rem;
}
.ml-0 {
	margin-left: 0 !important;
}
.ml-1 {
	margin-left: 0.5rem;
}
.ml-2 {
	margin-left: 1rem;
}
.ml-3{
	margin-left: 1.5rem;
}
.ml-4{
	margin-left: 2rem;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: .5rem;
}
.mt-2 {
	margin-top: 1rem;
}
.mt-3 {
	margin-top: 1.5rem;
}
.mt-4 {
	margin-top: 2rem;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-1 {
	margin-bottom: .5rem;
}
.mb-2 {
	margin-bottom: 1rem;
}
.mb-3 {
	margin-bottom: 1.5rem;
}
.mb-4 {
	margin-bottom: 2rem;
}
.my-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.my-1 {
	margin-top: .5rem;
	margin-bottom: .5rem;
}
.my-2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.my-3 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
.my-4 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.p-1 {
	padding: .5rem;
}
.p-2 {
	padding: 1rem;
}
.p-3 {
	padding: 1.5rem;
}
.p-4 {
	padding: 2rem;
}
.w-70 {
	width: 70% !important;
}
.w-100 {
	width: 100% !important;
}
.h-100 {
	height: 100% !important;
}
.min-height-30 {
	min-height: 30rem;
}
.d-flex {
	display: flex;
}
.flex-column {
	flex-direction: column;
}
.d-grid {
	display: grid;
}
.justify-content-space-between {
	justify-content: space-between;
}
.justify-content-end {
	justify-content: end;
}
.no-wrap {
	white-space: nowrap;
}

.simplebar-scrollbar:before {
	background-color: $white;
}

.slick-track {
	display: flex;
}

.d-none {
	display: none;
}
.flex-between {
	display: flex;
	justify-content: space-between;
}
p {
	display: inline-block;
}
.count-followers {
	font-size: 2rem;
}

.button {
	text-decoration: none;
	color: $white;
	padding: 15px 30px;
	span {
		line-height: 2rem;
	}
	&.button-grey {
		background: #474e5c;
		border-radius: 26px;
	}

	&.button-template {
		background: radial-gradient(50% 50% at 50% 50%, #4E45AF 0%, #362CAE 100%);
		border-radius: 50px;
	}

	&.button-transparent {
		border-radius: 50px;
		border: 1px solid $white;
	}

	&.button-blue {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50px;
		border: none;
		&:hover {
			background-color: hsla(0,0%,100%,.2);
			transition: background-color .2s ease-in-out;
		}
		&.selected {
			background-color: #4d72be;
		}
	}
	&.small {
		padding: 10px 15px;
	}
}
//positions absolute
.top-left {
	top: 0;
	left: 0;
}
.top-right {
	right: 0;
	top: 0;
}
.bottom-right {
	bottom: 0;
	right: 0;
}
.bottom-left {
	bottom: 0;
	left: 0;
}
.clear {
	clear: both;
}
.custom-radio {
	input {
		/* hiding browser el */
		appearance: none;
		/* Safari support */
		-webkit-appearance: none;
		border: 0.2rem solid #fff;
		background-color: #e8e8e8;
		border-radius: 50%;
	}

	input[type="radio"] {
		height: 1.2rem;
		width: 1.2rem;
		margin-right: 0.5rem;
	}

	/* keyboard navigation focus appearance */
	input:focus-visible {
		outline-offset: 0;
	}

	input {
		outline: 1px solid #999;
	}

	input:hover {
		outline-color: #f90c0c;
	}

	input:checked {
		outline-color: #f90c0c;
		background-color: #f90c0c;

		animation: outline-checked;
		animation-duration: 0.1s;
		animation-iteration-count: 4;
		animation-direction: alternate;
		animation-timing-function: linear;
	}
}

.success-animation {
	// Define vars we'll be using
	$brand-success: #5cb85c;
	$loader-size: 7em;
	$check-height: calc($loader-size/2);
	$check-width: calc($check-height/2);
	$check-left: calc(calc($loader-size/6) + calc($loader-size/12));
	$check-thickness: 3px;
	$check-color: $brand-success;

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	background-color: #0F347F;
	z-index: 9;

	.loader {
		@include center(true, true);
	}

	.circle-loader {
		border: 3px solid rgba(0, 0, 0, 0);
		border-left-color: $check-color;
		animation: loader-spin 1.2s infinite linear;
		transform-origin: center;
		display: inline-block;
		border-radius: 50%;
		width: $loader-size;
		height: $loader-size;
		margin: auto;
	}

	.load-complete {
		-webkit-animation: none;
		animation: none;
		border-color: $check-color;
		transition: border 500ms ease-out;
		.checkmark {
			display: block !important;
		}
	}

	.checkmark {
		display: none;

		&.draw:after {
			animation-duration: 800ms;
			animation-timing-function: ease;
			animation-name: checkmark;
			transform: scaleX(-1) rotate(135deg);
		}

		&:after {
			opacity: 1;
			height: $check-height;
			width: $check-width;
			transform-origin: left top;
			border-right: $check-thickness solid $check-color;
			border-top: $check-thickness solid $check-color;
			content: '';
			left: $check-left;
			top: $check-height;
			position: absolute;
		}
	}

	@keyframes loader-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes checkmark {
		0% {
			height: 0;
			width: 0;
			opacity: 1;
		}
		20% {
			height: 0;
			width: $check-width;
			opacity: 1;
		}
		40% {
			height: $check-height;
			width: $check-width;
			opacity: 1;
		}
		100% {
			height: $check-height;
			width: $check-width;
			opacity: 1;
		}
	}
}
.cursor-pointer {
	cursor: pointer;
}

.btn-stripe {
	border-radius: 8px;
	color: #ffffff;
	padding: 1rem 2rem;
	background-color: #204288;
	border: 1px solid #c3c2c2;

	&:hover:not(:disabled) {
		background: #ffffff33 !important;
	}

	&.small {
		font-size: small;
	}
}
.checkbox {
	.d-block {
		div {
			display: block !important;
		}
	}
	label {
		padding: 0;
	}
}