.events {
	width: 100%;
	margin: 3rem auto;
	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h3 {
			text-transform: inherit;
			font-weight: bold;
			font-size: 1.8rem;
			margin: auto 0;
		}
		a {
			text-decoration: none;
			cursor: pointer;
			color: white;
			font-size: 1.2rem;
		}
		h5 {
			font-size: 1.6rem;
			transition: transform ease 300ms;
			svg {
				vertical-align: middle;
				height: 1.1rem;
				margin-left: 5px;
				transition: transform ease 300ms;
			}
			&:hover {
				transform: translate(-25px ,0);
				svg{
					transform: translate(35px ,0);
				}
			}
		}
		.live:after {
			content: " ";
			position: absolute;
			height: 10px;
			width: 10px;
			border-radius: 2rem;
			background-color: $red;
			margin: 5px;
		}
	}
	> div {
		width: 100%;
		margin: 0;
	}
	.slides-container {
		&::after {
			content: "";
			display: none;
			background: linear-gradient(
				270.21deg,
				rgb(9, 22, 41) 10%,
				rgba(9, 22, 41, 0) 99.82%
			);
			width: 5%;
			height: 100%;
			position: fixed;
			top: 0;
			right: 0;
			z-index: 5;
		}
		position: relative;
		max-width: 95%;
		margin-left: auto;
		margin-top: 2rem;
		.slick-track {
			margin-left: 0;
		}
		.slick-slide {

		}
		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: 50%;
			left: -5rem;
			z-index: 14;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				position: relative;
				z-index: 20;
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: 2rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}

		.event {
			height: 25rem !important;
			width: 97% !important;
			position: relative;
			img {
				width: 100%;
				height: 100%;
			}
			.title-container {
				background: linear-gradient(180deg, rgba(26, 54, 141, 0) 10.64%, #091641 95.86%);
				position: absolute;
				bottom: -2px;
				text-transform: capitalize;
				font-size: 1.8rem;
				padding: 8px;
				left: 0;
				right: 0;
				h4 {
					margin-top: 1rem;
					margin-bottom: 1rem;
				}
			}
		}
		a {
			color: $white;
			text-decoration: none;
			z-index: 5;
			position: relative;

			.last-card {
				border-radius: 8px;
				overflow: hidden;
				position: relative;
				height: 25rem !important;

				min-width: 40rem !important;
				max-width: 20% !important;

				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				&::before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(9, 22, 41, 0.84);
					z-index: 2;
				}
				svg {
					fill: $white;

					z-index: 5;
					width: 5rem;
				}

				h3 {
					font-size: 3rem;
					text-align: center;
					position: relative;
					margin-top: 1rem;
					z-index: 5;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					z-index: -1;
				}
			}
		}
	}
}
@media (max-width: $mobile) {
	.events {
		.slides-container {
			.title-container {
				padding: 0.4rem 0.5rem 1rem;
			}
			.arrow-slideshow {
				top: calc(50% - 4.5rem);

				&.left {
					left: unset;
				}
			}
		}
	}
}
