#avatar {
	display: block;
	margin-top: 2rem;
	img {
		width: 13rem;
		aspect-ratio: 1/1;
		object-fit: cover;
		border-radius: 50%;
		overflow: hidden;

	}
	.data {
		display: flex;
		background: rgba(255, 255, 255, 0.12);
		border: none;
		border-radius: 5px;
		position: relative;
		.image {
			margin: 2rem auto;
			position: relative;
		}
		form {
			display: flex;
			z-index: 9999;
			align-items: center;
			position: absolute;
			margin: 0;
			left: calc(50% - 23px);
			top: calc(50% - 23px);
			input {
				position: absolute;
				top: -9999rem;
				left: -9999rem;
			}
			label {
				vertical-align: middle;
				cursor: pointer;
				padding: 1rem;
				border-radius: 50%;
				background-color: #1A1D56;
			}
		}
	}
}
