.account-page {
	display: flex;
	position: relative;
	.banner {
		min-width: 53%;
		max-width: 53%;
		position: relative;
		> img {
			width: 100%;
			height: 100vh;
			object-fit: cover;
			object-position: center;
		}

	}

	.account {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.256) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(20px);
		.account-container {
			position: relative;
			width: 55%;
			margin: 16rem auto 5rem;

			h2 {
				font-size: 2.4rem;
				font-weight: bolder;
				margin-bottom: 2rem;
				display: none;
				&.display,
				&.untouch {
					display: block !important;
				}
			}

			.input {
				position: relative;
				::-webkit-calendar-picker-indicator {
					filter: invert(1);
				}
				display: flex;
				flex-flow: row wrap;
				margin-bottom: 2rem;

				input {
					-webkit-appearance: none;
					display: flex;
					width: 100%;
					min-width: 25rem;
					height: 2.4rem;
					position: relative;
					padding: 1.3rem 2.2rem;
					background: rgba(157, 157, 157, 0.12);
					border: none;
					border-radius: 5px;
					color: rgba(157, 157, 157, 1);
					font-family: $fontRegular;
					font-size: 1.8rem;
					&::placeholder {
						color: var(--placeholder-color);
					}
				}
				img {
					position: absolute;
					top: 1rem;
					right: 1rem;
					height:30px;
					width: 30px;
					cursor: pointer;

				}
				&.error::before {
					content: " ";
					display: block;
					position: absolute;
					left: -1.5rem;
					height: 100%;
					width: 0.3rem;
					border-radius: 0.2rem;
					background-color: $red;

					animation: wiggle 0.1s linear 4;
				}
			}
			#submit {
				border-radius: 5px;
				padding: 1.7rem;
				width: 100%;
				border: none;
				color: $white;
				font-family: $fontRegular;
				font-size: 1.8rem;
				margin-bottom: 2rem;
			}
			.errorDisplay {
				position: absolute;
				top: 120%;
				transform: translateY(-50%);
				right: 1rem;
				color: $red;

				font-size: 1.3rem;
				font-weight: bolder;
				text-transform: uppercase;
				animation: wiggleSmall 0.1s linear 3;
			}
			.page-select-right {
				justify-content: flex-end !important;
			}
			.page-select, .page-select-right {
				display: flex;
				justify-content: space-between;
				margin-top: 2rem;
				#submit {
					background-color: $green !important;
					padding-right: 3.5rem !important;
					height: 4rem;
					line-height: 0;
					text-align: left;
				}
				label {
					position: relative;
					height: 100%;
					width: 45%;
					&::after {
						cursor: pointer;
						content: " ";
						width: 2.5rem;
						height: 2.5rem;
						position: absolute;
						right: 1.4rem;
						top: calc(50%);
						transform: translateY(-50%);
						mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi41MzY0IDMuNTc3MDlDMTIuMTc2MyAzLjI1MTY1IDExLjU5MjUgMy4yNTE2NSAxMS4yMzI0IDMuNTc3MDlDMTAuODcyMyAzLjkwMjUyIDEwLjg3MjMgNC40MzAxNiAxMS4yMzI0IDQuNzU1NkwxNi4xMTI5IDkuMTY2MzRINS40Mjk4OUM0LjkyMDY0IDkuMTY2MzQgNC41MDc4MSA5LjUzOTQ0IDQuNTA3ODEgOS45OTk2N0M0LjUwNzgxIDEwLjQ1OTkgNC45MjA2NCAxMC44MzMgNS40Mjk4OSAxMC44MzNIMTYuMTEyOUwxMS4yMzI0IDE1LjI0MzhDMTAuODcyMyAxNS41NjkyIDEwLjg3MjMgMTYuMDk2OCAxMS4yMzI0IDE2LjQyMjNDMTEuNTkyNSAxNi43NDc3IDEyLjE3NjMgMTYuNzQ3NyAxMi41MzY0IDE2LjQyMjNMMTguOTkxIDEwLjU4ODlDMTkuMzUxMSAxMC4yNjM1IDE5LjM1MTEgOS43MzU4NiAxOC45OTEgOS40MTA0MkwxMi41MzY0IDMuNTc3MDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: $white;
					}
				}
				button {
					background-color: #2e56e4;
					border-radius: 4rem;
					display: flex;
					justify-content: space-between;
					width: 45%;
					height: 4rem;
					color: $white;
					border: none;
					padding-right: 3.8rem;
					padding-left: 3.8rem;
					&:first-of-type {
						background-color: #6f7fb5;
					}
					p {
						margin: auto 0;
						font-size: 16px;
					}
					svg {
						fill: $white;
						margin: auto 0;
						&.left {
							margin-right: 2rem;
						}
						&.right {
							margin-left: 2rem;
						}
					}
				}
			}


			.error {
				.validator {

					div {
						svg {
							vertical-align: middle;
							fill: $red;
							width: 1.2rem;
							margin: 0 1rem 0 0;

							&.validate {
								fill: $green;
							}
						}
					}
				}
			}

		}
	}
	.reset_pass, .register {
		margin: 1.8rem 0;
		display: block;
		a {
			color: #41FFFF;
		}
	}
}
.confirm-message {
	&.display {
		visibility: visible;
		opacity: 1;
		transition: all $fade;
		line-height: 2rem;
	}

	visibility: hidden;

	opacity: 0;
	transition: all $fade;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	display: flex;
	flex-direction: column;

	background: radial-gradient(
					100% 100% at 0% 0%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
	);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(20px);
	border-radius: 1rem;
	padding: 4rem;
	justify-content: center;
	align-items: center;
	text-align: center;

	.svg {
		background-color: $green;
		border-radius: 50%;
		width: 8rem;
		height: 8rem;
		svg {
			fill: $white;
			position: relative;
			top: 1.5rem;
			width: 5rem;
			height: 5rem;
		}
	}

	h2 {
		width: 100%;
		position: static;
		margin-top: 2rem;
		margin-bottom: 2rem;
		display: block;
		font-weight: bold;
		font-size: 3rem;
	}

	a {
		text-decoration: none;
		color: $white;
		margin-top: 2rem;
		background-color: $green;
		padding: 1rem;
		border-radius: 2rem;
		width: 50%;
		min-width: 110px;
		svg {
			margin-left: 1rem;
			fill: $white;
			vertical-align: middle;
		}
	}
	.validate_link {
		background: none;
		color: #65a4faff;
		text-decoration: underline;
		margin-top: 5px;
		&:hover {
			color: white;
			cursor: pointer;
		}
	}
}
@media (max-width: $mobile) {
	.account-page {
		.banner {
			display: none;
		}
		.account {
			.account-container {
				margin: 10rem auto 5rem !important;
				width: 89% !important;
			}
		}
	}
	.confirm-message {
		width: 68%;
		top: 50%;
		h2 {
			line-height: 1;
		}
	}
}
