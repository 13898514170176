:root {
	--select-border: #777;
	--select-focus: blue;
	--select-arrow: var(--select-border);
}

.languages {
	&.block {
		.grid {
			margin-top: 2rem;
			select {
				font-size: 1.6rem;
				appearance: none;
				background-color: transparent;
				border: none;
				padding: 0 1em 0 0;
				margin: 0;
				width: 100%;
				font-family: inherit;
				cursor: inherit;
				line-height: inherit;
				z-index: 1;
				outline: none;
				&::-ms-expand {
					display: none;
				}
				&:disabled {
					background-color: transparent;
					background-image: none;
					color: white;
					padding-left: 0;
				}
			}

			.select {
				display: grid;
				grid-template-areas: "select";
				align-items: center;
				position: relative;
				min-width: 15ch;
				max-width: 30ch;
				border: 1px solid var(--select-border);
				border-radius: 0.25em;
				padding: 0.25em 0.5em;
				cursor: pointer;
				line-height: 1.1;
				background-color: #fff;
				background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

			}

		}
	}
}
@media (max-width: $mobile) {
	.notifications {
		width: auto;
	}
}