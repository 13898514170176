@import "_settings.scss";

//CENTER ELEMENT VERTICAL OR HORIZONTAL OR BOTH
@mixin center($v, $h) {
	position: absolute;
	@if $v and not $h {
		top: 50%;
		transform: translateY(-50%);
	} @else if $h and not $v {
		left: 50%;
		transform: translateX(-50%);
	} @else if $h and $v {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin icons($source) {
	//ADD ICON
	&::after {
		cursor: pointer;
		content: " ";
		width: 1rem;
		height: 1rem;
		margin-left: 0.5rem;
		display: inline-block;
		mask: url($source);
		mask-size: contain;
		mask-repeat: no-repeat;
		background-color: $white;
	}
}

@keyframes wiggle {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}
@keyframes wiggleSmall {
	0% {
		transform: translateY(-50%) translateX(0%);
	}
	50% {
		transform: translateY(-50%) translateX(10%);
	}
	100% {
		transform: translateY(-50%) translateX(0%);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes wiggleFade {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	85% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
