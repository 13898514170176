.category {
	a {
		text-decoration: none !important;
	}
	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h3 {
			text-transform: inherit;
			font-weight: bold;
			font-size: 1.8rem;
			margin: auto 0;
		}
		h5 {
			cursor: pointer;
			font-size: 1.2rem;
			svg {
				vertical-align: middle;
				height: 1.1rem;
				fill: $white;
			}
		}
	}

	.category-container {
		margin-top: 2rem;
		position: relative;
		max-width: 90%;
		margin-right: auto;
		margin-left: auto;

		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: calc(50% - 3.5rem);
			left: -5rem;
			z-index: 6;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: -5rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}
		.slick-track {
			margin-left: 0;
		}
		.slick-slide {
			margin: 0 20px 0 0;
		}

		.slick-list {
			margin: 0 -20px 0 0;
			overflow: hidden;
		}
		.cat {
			position: relative;
			overflow: hidden;
			border-radius: 5px;
			cursor: default;
			.preview {
				height: auto;
				width: 100%;
				border-radius: 5px;
				transition: 0.5s all ease-in-out;
			}

			.title-block {
				position: absolute;
				bottom: -5rem;
				height: 40%;
				width: 100%;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 1.8rem;
				color: white !important;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				justify-content: center;
				background: linear-gradient(180deg, rgba(26, 54, 141, 0) 10.64%, #091641 95.86%);
				transition: all ease 0.5s;

				.title_content {

					transition: all ease 0.5s;

					h4 {
						margin-bottom: 4rem;
						position: relative;
						transition: all ease 0.5s;

						.bar {
							position: absolute;
							bottom: -1.4rem;
							left: 50%;
							transform: translate(-50%);
							transition: bottom ease 0.5s;
						}
					}

					.play {
						height: 5rem;
						width: 5rem;
						margin: auto;
						opacity: 0;

						.play_button {
							width: 100%;
							height: 100%;
							margin: auto;
						}
					}
				}
				
			}

			&:hover {
				cursor: pointer;

				.preview {
					transform: scale(1.1);
				}

				.title-block{
					height: 80%;
					bottom: 0;

					.title_content {
						transform: translateY(-2rem);

						h4 {
							margin-bottom: 1.5rem;

							.bar {
								position: absolute;
								bottom: calc(100% + 1.5rem);
							}
						}
						.play {
							opacity: 1;

							.play_button {
								cursor: pointer;
								.play_sign, .round {
									transition: all ease-in-out .5s;
								}
								&:hover {
									.round {
										fill: #ffffff;
									}
									.play_sign {
										fill: #362CAE;
									}
								}
							}
						}
					}

				}
			}
		}
	}
}
@media (max-width: $mobile) {
	.category {
		.category-container {
			.arrow-slideshow {
				top: calc(50% - 4.5rem);

				&.left {
					left: 1rem;
					right: unset;
				}
				&.right {
					left: unset;
					right: 1rem;
				}
			}
		}
	}
}
